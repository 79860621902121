import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "customize-highlights-on-home-screen"
    }}>{`Customize Highlights on Home Screen`}</h1>
    <hr></hr>
    <p>{`To customize the highlights on your app's homescreen from the Content Management System, follow these step-by-step instructions:`}</p>
    <ol>
      <li parentName="ol">{`Access the Content Management System and navigate to the Mobile Apps section from the left side menu options. Click on the Mobile App or Template you want to customize. `}</li>
      <li parentName="ol">{`In the Tab Manager, select the 'Home Screen' tab. Select 'Edit tab - Home Screen.' This action will load the Edit Homescreen Tab page. `}</li>
      <li parentName="ol">{`In the 'Homescreen Action Highlights' section, toggle which highlights you would like to display in your app. `}</li>
      <li parentName="ol">{`Please note the following require an Events Data Provider to be configured:`}
        <ul parentName="li">
          <li parentName="ul">{`Events - Today`}</li>
          <li parentName="ul">{`Events - Upcoming`}</li>
          <li parentName="ul">{`Rooming Bookings - Today`}</li>
          <li parentName="ul">{`Resource Bookings `}</li>
        </ul>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      